import React from "react"
import colors from "../colors"
import { PageLayout } from "../components/layouts"
import SEO from "../components/seo"
import PageWrapper from "../components/pageWrapper"
import TextBlock from "../components/textBlock"
import TextImageBlock from "../components/textImageBlock"
import PersonList from "../components/person/PersonList"
import {
  ScheduleVisitButton,
  RegisterForWebinarButton,
} from "../components/buttons"

const iconProps = {
  size: "60px",
  color: colors.darkPurple,
}

const LongTermCareInsuranceSpecialist = () => {
  return (
    <PageLayout>
      <SEO
        title="Long Term Care Insurance Specialist | About Corey Rieck | LTCPG"
        ogDescription="Using a long term care insurance specialist who has exclusive focus on long term care planning will ensure you get the best policy for you and your family."
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Why Use a Long Term Care Insurance Specialist?</h1>
            <p>
              One of the things that distinguishes The Long Term Care Planning
              Group is our exclusive focus on long term care planning since
              2001. <a href="/about/">Corey Rieck and his team</a> are considered experts in long term
              can planning. Clients appreciate working with us as their long
              term care insurance specialist for several reasons.
            </p>
            <RegisterForWebinarButton />
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/AdobeStock_107811517.jpg"
              alt="Long Term Care Insurance Specialist"
            />
          </div>
        }
      />
      <TextImageBlock
        image={
          <img
            src="/images/AdobeStock_137773804.jpg"
            alt="Long Term Care Insurance Specialist"
            className="right-point"
          />
        }
        imageGrow
        textPadded
        text={
          <div>
            <h2>Industry Expertise and Relationships</h2>

            <p>
              We have working relationships with all the <a href="/long-term-care-insurance-companies/">major carriers in the
              long term care marketplace</a>. We only care that our clients have
              options and will work with carriers to find the best match based
              on how he or she presents medically.
            </p>
            <p>
              As long term care insurance specialists, we take you to what is
              most amenable to your <a href="/long-term-care-insurance-medical-history/">medical history</a>. If there is no medical
              history, you will likely have a lot of options. If there is
              medical history, it almost always requires a second strategy for
              us to find a carrier more amenable to your medical history.
            </p>
            <p>
              Our clients find our confidential and consultative approach we
              take reassuring. They know we will achieve the best possible
              outcome for them as their long term care insurance specialist.
            </p>
            <p>
              It's important for us as your long term care insurance specialist
              to understand your tenure, medical history, surgical history and
              medications they've taken in the past or are taking currently.
              Then we can connect them to the right carrier that fits them from
              an underwriting perspective.
            </p>
            <p>
              To have the most options and get the best premium choices, we
              highly recommend you do your long term care planning and purchase
              the insurance when you're younger than 50 and most likely
              healthier than if you wait until you’re older.
            </p>
          </div>
        }
      />
      <PageWrapper maxWidth color="base">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h2><a href="/long-term-care-info/">Long Term Care Education</a> is Foundational</h2>
              <p>
                As your long term care insurance specialist, we will help you
                understand what long term care is and is not. We will ask you
                questions and listen to your experience with long term care
                either with a loved one or someone else you know personally.
                It’s important for you to also be given the chance to understand
                the history of long term care insurance so you know how premiums
                are set. Since there are long term care location and venue
                options, we’ll need to talk about where you want to live and
                what level of care you believe will be best for yourself or a
                loved one.
              </p>
              <p>
                One of the most prevalent myths about long term care is that
                <a href="/government-does-not-pay-for-long-term-care/">Medicaid health insurance or disability insurance</a> pays for long
                term care expenses. This is a confusing topic we’ll explain
                carefully so you will make an informed decision on where long
                term care fits into your overall financial planning.
              </p>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/AdobeStock_390977412.jpg"
                alt="Long Term Care Insurance Specialist"
              />
              <p>
                Knowing all your <a href="/long-term-care-insurance-options/">options for the continuum of long term care</a> services and
                housing is a top priority. We’ll look at costs in your area or
                the area you would prefer receiving long term care or possibly
                plan to retire.
              </p>
            </div>
          }
        />
      </PageWrapper>
      <PageWrapper maxWidth color="green">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img
                src="/images/corey-rieck-ltc-specialist.jpg"
                alt="Long Term Care Insurance Specialist"
              />
            </div>
          }
          textRight={
            <div>
              <h2>A word from Corey Rieck, MBA, CLTC</h2>
              <p>
                As a result of my firsthand family experience with the need for
                long term care, I decided in 2001 to devote the remainder of my
                career to helping individuals, companies and organizations
                become more fully educated on long term care.
              </p>
              <p>
                I have been fortunate to help thousands of people all over the
                United States on this very important matter. As a long term care
                insurance specialist, I am truly client-centric with the
                emphasis always on education first. I have worked with all the
                major <a href="/long-term-care-insurance-companies/">long term care insurance companies</a> at high levels and I
                remain truly carrier and instrument neutral. Said differently—I
                take the necessary time to understand the client needs and then
                explore the market fully on their behalf. My approach has been
                described as systematic, thorough and consultative. My goal is
                to always make people better off for having discussed this topic
                with me.
              </p>
              <p>
                My educational offers are multi-dimensional highlighted by
                web-based tools, paper-based tools and perhaps most
                importantly—people based tools.
              </p>
              <p>
                There are many ways for you to benefit from my knowledge,
                experience and approach as a long term care insurance specialist
                shared with individuals, professional associations and companies.
                Give us a call to talk you through your long term care options
                (678) 814-5088. This is a complimentary, no-obligation
                conversation.
              </p>
            </div>
          }
        />
      </PageWrapper>
      <PageWrapper>
        <div
          style={{
            height: "1px",
            width: "100%",
            borderTop: "3px solid whitesmoke",
            display: "block",
            margin: "50px 0 50px 0",
          }}
        ></div>
        <PersonList shortnames={["corey-rieck"]} />
      </PageWrapper>
    </PageLayout>
  )
}

export default LongTermCareInsuranceSpecialist
